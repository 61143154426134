<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="7" order-sm="1">
        <v-subheader>Listado días festivos</v-subheader>
        <v-divider class="mb-2"></v-divider>
        <v-data-table
          fixed-header
          :items="festivos"
          :headers="festivosHeader"
          class="fixed-checkbox"
          item-key="idEvento"
          :single-select="false"
          show-select
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [10, 25, 50, -1],
          }"
          checkbox-color="secondary"
        >
          <template v-slot:body.prepend="{ headers }">
            <table-filters
              :headers="headers"
              :items="festivos"
              v-model="inlineFilters"
            ></table-filters>
          </template>

          <template v-slot:item.start="{ item }">
            <v-chip>
              <v-icon left>mdi-calendar</v-icon>
              {{ parseDate(item.start) }}
            </v-chip>
          </template>

          <template v-slot:item.end="{ item }">
            <v-chip>
              <v-icon left>mdi-calendar</v-icon>
              {{ parseDate(item.end) }}
            </v-chip>
          </template>

          <template v-slot:item.acciones="{ item }">
            <div class="d-flex">
              <v-btn
                @click.stop="festivo = item"
                small
                rounded
                color="secondary"
              >
                <v-icon left>mdi-pencil</v-icon>
                Editar
              </v-btn>
              <v-btn
                @click.stop="deleteFestivos(item)"
                small
                class="ml-1"
                rounded
                color="error"
              >
                <v-icon left>mdi-delete</v-icon>
                Eliminar
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="5" order-sm="1">
				<v-subheader>Crear nuevo festivo</v-subheader>
				<v-divider class="mb-2"></v-divider>
        <NuevoFestivo
          @reload="getFestivos()"
          :key="keyHelper"
          :value="festivo"
        />
      </v-col>
    </v-row>
		
		<ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { parseDate, perColumnFilter } from "@/utils/index.js";

export default {
  components: {
    NuevoFestivo: () => import("../components/NuevoFestivo.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
		    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      festivos: [],
      festivo: {},
      inlineFilters: {},
      keyHelper: 0,
      loading: false,
      festivosHeader: [
        {
          text: "Nombre",
          value: "name",
          cellClass: "pa-1 text-no-wrap font-weight-bold",
        },
        { text: "Localidad / Área", value: "localidad" },
        { text: "Inicio", value: "start", dataType: "date" },
        { text: "Fin", value: "end", dataType: "date" },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
          filterable: false,
        },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-1 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
    };
  },
  watch: {
    festivo() {
      this.keyHelper++;
    },
  },
  mounted() {
    this.getFestivos();
  },
  methods: {
    parseDate,
    async getFestivos() {
      this.keyHelper++;
      this.festivo = {};
      const { data } = await axios({
        url: "/festivos",
      });
      this.festivos = data;
      this.loading = false;
    },
    async deleteFestivos({ idFestivo, name }) {

			const confirmation = await this.$refs.confirm.open(
				`Festivo: ${name}`,
        `¿Estás seguro de que quieres eliminar el festivo?`,
        { color: "error" }
      );
      if (!confirmation) return;

      const { data } = await axios({
        method: "DELETE",
        url: "/festivos/" + idFestivo,
      });
      this.getFestivos();
    },
  },
};
</script>

<style>
</style>